<script setup>
/* Legacy component: Should be replaced by ContextSelector when navbar doesn't need to support CT anymore */
// Component doesn't spicify layout for Desctop, needs to be remaked or removed

import { ref, watch, reactive, onMounted } from 'vue';

import { getOrCreateProcedure, context } from 'o365-modules';
import { alert } from 'o365-vue-services';

import FolderView from "./components.orgunit.FolderView.vue";

import { DataLookupControl } from 'o365-datalookup';
import { DataLookupList } from 'o365-datalookup';
import { ONodeColumn } from "o365-nodedata"
import 'o365-nodedata';
    
const props = defineProps({
    hideTree: {
        type: Boolean,
        default: false
    },
    whereClause: String
});

const emits = defineEmits(['contextChanged']);

const activeTab = ref('#grid');
const lookupId = crypto.randomUUID();

let treeInitialized = false;
const currentContextIdPath = ref(context.idPath);

const _includeClosed = ref(false);
const _restrictToContext = ref(false);

let previousSearchString = '';
const _completeStructure = ref(false);
const fullSearchString = ref('');
const fullSearchResults = ref([]);
const currentFullSearchMatch = ref(0);
let fullSearchDebounce = null;

const procAddOrgUnitToPinnedList = getOrCreateProcedure({
    id:"procAddOrgUnitToPinnedList",
    procedureName: "sstp_System_LookupSelections",
});

const buildWhereClause = (opts = {}) => {
    const clauses = [];

    clauses.push('Deleted IS NULL')

    if (_restrictToContext.value && currentContextIdPath.value && !opts.skipContextFiltering) {
        clauses.push(`IdPath LIKE '${currentContextIdPath.value}%'`);
    }

    if (!_includeClosed.value) {
        clauses.push('Closed IS NULL');
    }

    if (props.whereClause) {
        clauses.push(props.whereClause);
    }

    return clauses.filter(x => x).join(' AND ');
}

watch([_restrictToContext, _includeClosed, currentContextIdPath], () => {
    dsOrgUnits.recordSource.whereClause = buildWhereClause();
    dsOrgUnits.load();
});

watch(activeTab, async  () => {
    if ((activeTab.value === '#tree') && !treeInitialized) {
        await dsOrgUnitsTree.load();

        loadTree();
    }
});

watch(() => fullSearchString.value, () => {
    doFullStructureSearch();
});

watch(() => _completeStructure.value, (pValue) => {
    if (pValue && dsOrgUnitsTree.recordSource.filterString) {
        dsOrgUnitsTree.filterObject.clear();
    }
});

const nonContextOrgUnitDataObjectConfig = {
    viewName: 'stbv_System_OrgUnits',
    loadRecents: false,
    fields: [
        { name: 'PrimKey' },
        { name: 'ID', type: 'number' },
        { name: 'Name' },
        { name: 'Title' },
        { name: 'OrgUnit' },
    ]
};

const orgUnitDataObjectConfig = {
    viewName: 'sviw_System_OrgUnitsChooserToolbar',
    loadRecents: true,
    distinctRows: true,
    maxRecords: 25,
    fields: [
        { name: 'PrimKey', type: 'string' },
        { name: 'ID', type: 'number' },
        { name: 'IdPath', type: 'string' },
        { name: 'OrgUnit', type: 'string' },
        { name: 'Closed', type: 'date' },
        { name: 'Name', type: 'string' },
        { name: 'Title', type: 'string' },
        { name: 'Domain_ID', type: 'number' },
        { name: 'Level', type: 'number' },
        { name: 'UnitType', type: 'string' },
        { name: 'NamePath', type: 'string', sortOrder: 1, sortDirection: 'asc' },
        { name: 'Parent', type: 'string' },
        { name: 'AccessIdPath', type: 'string' }
    ]
};

const dsOrgUnits = $getOrCreateDataObject({
    ...orgUnitDataObjectConfig,
    id: 'o_dsOrgUnits' + lookupId,
    whereClause: buildWhereClause(),
});

// Create a slightly modified copy of dsOrgUnits so that we can use it in the tree
const dsOrgUnitsTree = $getOrCreateDataObject({
    ...orgUnitDataObjectConfig,
    id: 'o_dsOrgUnits' + crypto.randomUUID(),
    selectFirstRowOnLoad: false,
    loadRecents: false,
    whereClause: buildWhereClause({ skipContextFiltering: true })
});

const lookupControl = reactive(new DataLookupControl({
    dataObject: dsOrgUnits,
    noClear: true,
    filterRow: true,
    multiselect: false,
    bind: org => { orgUnitClicked(org) },
    columns: [
        { field: 'OrgUnit',  headerName: 'Org Unit Name', cellTitle: row => 'ID: ' + row.ID + ', ' + row.OrgUnit, width: 350, flexWidth: 50 },
        { field: 'UnitType', headerName: 'Org Unit Type', width: 120, flexWidth: 25 },
        { field: 'Parent', width: 200, flexWidth: 25 }
    ]
}));

const loadNonContextUnit = async (_pData, pNode) => {
    try {

        const dsNonContextOrgUnits = $getOrCreateDataObject({
            ...nonContextOrgUnitDataObjectConfig,
            id: 'o_dsNonContextOrgUnits' + lookupId,
        })
        const id = +pNode.key.split('/').at(-1);
        const item = await dsNonContextOrgUnits.recordSource.refreshRowById(id, {
            returnExisting: true
        });
        return item.item;
    } catch (ex) {
        return Promise.resolve({
            Name: $t('unknown'),
        });
    }
};

const loadTree = () => {
    dsOrgUnitsTree.nodeData.enable();
    dsOrgUnitsTree.nodeData.addConfiguration({ type: 'hierarchy', idPathField: 'IdPath', requireParents: false, getSummaryItem: loadNonContextUnit });
    dsOrgUnitsTree.nodeData.init();

    treeInitialized = true;
}

const orgUnitClicked = async (row) => {
    if (activeTab.value === '#tree') {
        try {
            await procAddOrgUnitToPinnedList.execute({
                Pinned: null,
                Record_ID: row.ID,
                ViewName: 'sviw_System_OrgUnitsChooserToolbar',
            });
        } catch(e) {
            alert($t('Unable to pin selection.'), 'warning', { autohide: true, duration: 3000 });
        }
    }

    emits('contextChanged', row);
    dsOrgUnits.load();
}

function clearFullSearch() {
    fullSearchString.value = '';
    fullSearchResults.value.splice(0, fullSearchResults.value.length);
    dsOrgUnitsTree.nodeData.root[0]?.expandTo();
}

function doFullStructureSearch(pEvent) {
    if (!fullSearchString.value) {
        fullSearchResults.value.splice(0, fullSearchResults.value.length);
        return;
    }

    if (fullSearchString.value == previousSearchString) {
        if (pEvent?.shiftKey) {
            previousMatch();
        } else {
            nextMatch();
        }
        return;
    }

    if (fullSearchDebounce) { clearTimeout(fullSearchDebounce); }
    fullSearchDebounce = setTimeout(async () => {
        fullSearchResults.value.splice(0, fullSearchResults.value.length);
        const matches = await dsOrgUnitsTree.recordSource.retrieve({
            filterString: `[OrgUnit] LIKE '%${fullSearchString.value}%'`,
            fields: [{ name: 'PrimKey' }],
            maxRecords: -1
        });
        previousSearchString = fullSearchString.value;
        fullSearchResults.value.push(...matches);
        currentFullSearchMatch.value = 0;
        navigateToMatch(currentFullSearchMatch.value);
        fullSearchDebounce = null;
    }, 500);
}

function nextMatch() {
    if (currentFullSearchMatch.value + 1 >= fullSearchResults.value.length) {
        currentFullSearchMatch.value = 0;

    } else {
        currentFullSearchMatch.value += 1;
    }
    navigateToMatch(currentFullSearchMatch.value);
}

function previousMatch() {
    if (currentFullSearchMatch.value - 1 < 0) {
        currentFullSearchMatch.value = fullSearchResults.value.length - 1;

    } else {
        currentFullSearchMatch.value -= 1;
    }
    navigateToMatch(currentFullSearchMatch.value);
}

function navigateToMatch(pIndex) {
    const match = fullSearchResults.value[pIndex];
    if (match) {
        const node = dsOrgUnitsTree.nodeData.findNodeByFetchKey(match.PrimKey);
        node.expandTo()
    }
}

// call parent file function(o365.controls.NavBar.js)
const hideComponent = () => {
    window.parent.postMessage('hideChooser', '*');
}

onMounted(() => {
    dsOrgUnits.load();
    window.dsOrgUnitsTree = dsOrgUnitsTree;
});

</script>

<template>
    <OTabs  @onShow="(e) => activeTab = e.activeTab.getAttribute('data-bs-target')">
        <template #afterNav>
            <div class="d-flex align-items-center gap-2 m-2 ms-auto">
                <template v-if="activeTab !== '#tree'">
                    <div class="form-check form-switch form-check-reverse">
                        <input class="form-check-input" :id="'restrict-to-context-' + lookupId" type="checkbox" v-model="_restrictToContext">
                        <label class="form-check-label" :for="'restrict-to-context-' + lookupId">{{$t("Restrict to context")}}</label>
                    </div>

                    <div class="form-check form-switch form-check-reverse">
                        <input class="form-check-input" :id="'include-closed-' + lookupId" type="checkbox" v-model="_includeClosed">
                        <label class="form-check-label" :for="'include-closed-' + lookupId">{{$t("Include closed")}}</label>
                    </div>
                </template>

                <div v-if="activeTab === '#tree'" class="form-check form-switch form-check-reverse">
                    <input class="form-check-input" :id="'complete-structure-' + lookupId" type="checkbox" v-model="_completeStructure">
                    <label class="form-check-label" :for="'complete-structure' + lookupId">{{$t("Complete structure")}}</label>
                </div>

                <div>
                    <button style="background:none; border:none" @click = "hideComponent">
                        <i class="bi bi-x-square"></i> 
                    </button>
                </div>
            </div>
        </template>
        <OTab   :title="$t('Org Units List')" id="grid" active>
            <div class="d-flex flex-column h-100">
                <DataLookupList  :dataLookupControl="lookupControl"></DataLookupList>
            </div>
        </OTab>

        <OTab v-if="!hideTree" :title="$t('Org Structure')" id="tree">
            <FolderView
                :tree-object="dsOrgUnitsTree"
                :ds-org-units="dsOrgUnits"

                @contextChanged = "orgUnitClicked"
            />
        </OTab>
    </OTabs>
</template>